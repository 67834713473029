import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import { EEndpointLastUpdateInfoType } from "../areas/general/dto";
import { EndpointLastUpdateInfoService } from "../areas/general/service";

type LastUpdateDateByType = Partial<Record<EEndpointLastUpdateInfoType, Date>>;

interface DataCache<T> {
  data: T;
  lastUpdatedDate: Date;
}

class CachedEndpoints {
  private endpointLastUpdate: LastUpdateDateByType = {};

  public async getData<T>(
    type: EEndpointLastUpdateInfoType,
    key: string,
    serviceCall: () => Promise<T>,
  ): Promise<T> {
    const cacheKey: StorageKeyInfo<DataCache<T> | undefined> = {
      key: `CachedEndpoints_${type}_${key}`,
      pathIndependant: true,
    };

    let cache = LocalStorageUtil.get(cacheKey, undefined);

    const endpointLastUpdate = this.endpointLastUpdate[type]!;

    if (endpointLastUpdate.getTime() !== cache?.lastUpdatedDate.getTime()) {
      cache = {
        data: await serviceCall(),
        lastUpdatedDate: endpointLastUpdate,
      };
      LocalStorageUtil.set(cacheKey, cache);
    }

    return cache.data;
  }

  public getLastUpdatedDate(type: EEndpointLastUpdateInfoType): Date {
    return this.endpointLastUpdate[type]!;
  }

  public async loadEndpointLastUpdate(branchId: number): Promise<void> {
    const result =
      await new EndpointLastUpdateInfoService().getEndpointLastUpdateInfo({
        branchId: branchId,
      });
    if (result.lastUpdateInfos) {
      for (const info of result.lastUpdateInfos) {
        this.endpointLastUpdate[info.infoType] = info.lastUpdatedOnServer;
      }
    } else {
      throw new Error("No lastUpdateInfos returned");
    }
  }
}

const cachedEndpoints = new CachedEndpoints();
export default cachedEndpoints;
